.slider {
  max-width: 72.4rem;
  width: 58%;
  flex-shrink: 0;
  margin-left: 3.2rem;

  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
  margin: 0 !important;
}

.sliderItem {
  position: relative;
  padding-top: calc(9 / 16 * 100%);
}

@media screen and (max-width: 1000px) {
  .slider {
    width: 100%;
    position: absolute !important;
    top: calc(100% + 2rem);
    padding-top: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .slider {
    padding-bottom: 2.4rem !important;
  }
}
